const config = {
  ui_domain: 'testapp.kalixhealth.com',
  kalix_path: 'https://www.kalixhealth.com',
  kalix_auth_path: window.location.origin + '/authproxy', // Auth proxy is on current domain
  help_path: 'https://help.kalixhealth.com/',
  anon_path: 'https://test-us-api.kalixhealth.com',
  websockets_path: 'wss://test-ws.kalix.co',
  emailDomain: 'emailtest.kalixhealth.com',
  templateOrgId: 10020,
  appInsights: {
    connectionString: 'InstrumentationKey=8b34f729-aebb-4c0e-ba93-be3e6c6b69f8;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/'
  },
  facebook: {
    pixelId: null
  },
  google: {
    recaptcha: '6Lf-6p0UAAAAABgs7G8O1Oz2e__1x8remR1N0ij_',
    firebaseConfig: null
  },
  here: {
    apiKey: 'jWy0RBpPktUYBZ4f1ZY0d7HH7DFMDUtAkTOHyDbnXyU',
    zoom: 15
  },
  intercom: {
    key: 'in0yfyy5'
  },
  square: {
    sandbox: true
  },
  rupa: {
    sandbox: true,
    baseUrl: 'https://labs-sandbox.rupahealth.com'
  },
  telehealth: {
    appId: 'vpaas-magic-cookie-e71239f306924f2b9d7adff1b47256e4'
  }
};

module.exports = config;