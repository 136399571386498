import { Fragment, forwardRef, useState, useRef, useCallback, useEffect, useId } from 'react';
import cc from 'classnames';
import { observer } from 'decorators';
import { makeStyles, combineRefs, useDisposable, useTheme } from 'hooks';
import escapeRegExp from 'escape-string-regexp';
import VirtualList from 'components/virtualList';
import { Paper, CircularProgress, MenuList, MenuItem, ListItemText, InputAdornment, Chip, Grid, Input, ListSubheader } from '@material-ui/core';
import Tether from 'components/tether';
import Throttler from 'utils/throttler';
import log from 'services/log';

const useStyles = makeStyles(theme => ({
  progress: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },
  multiInput: {
    minWidth: theme.spacing(15)
  },
  maxWidth: {
    maxWidth: '100%'
  },
  padded: {
    margin: theme.spacing(0, 1)
  }
}));

export default observer(forwardRef(function SelectAsync(props, outerRef) {
  let {
    value, loadOptions, throttleDelay, onChange, onSelect, onInputChange, allowFreeInput, onFreeInput, onBlur, onFocus, onKeyDown, onClick,
    containerHeight, rowHeight, variableHeight, renderItem, inputProps, valueKey, nameKey, clearOnSelect, isLoadingExternal, emptyOnFalseValue,
    menuWidth, emptyWithNoItems, multiple, hideMultipleItems, readOnly, disabled, hasLabel, onPaste, alwaysShowOptionsOnFocus,
    ...other
  } = props;

  nameKey = nameKey || 'name';
  valueKey = valueKey || 'value';

  // We may need to treat false value as 'empty' - there is a difference between a null value and treating as empty
  if (emptyOnFalseValue && value && !value[valueKey]) { value = null; }
  if (multiple && value == null) { value = []; }

  const instanceId = useId();
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const inputBlurTimeout = useRef(null);

  useEffect(() => () => {
    if (inputBlurTimeout.current) {
      clearTimeout(inputBlurTimeout.current);
      inputBlurTimeout.current = null;
    }
  }, []);

  const [ showItems, setShowItems ] = useState(false);
  const [ highlightIndex, setHighlightIndex ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ inputValue, setInputValue ] = useState(() => multiple ? '' : (value ? value[nameKey] : ''));
  const [ items, setItems ] = useState([]);

  const throttlerId = useRef(0);
  const throttler = useDisposable(() => new Throttler(throttleDelay));
  const lastAction = useRef(null);

  const reloadOptions = useCallback(v => {
    if (readOnly || disabled) { return; }

    setIsLoading(!!loadOptions);

    // If we don't have loadOptions, then skip the actual loading part
    if (!loadOptions) { return; }
    const loadId = ++throttlerId.current;

    throttler
      .push(() => loadOptions(v == null ? inputValue : v))
      .then(res => {
        if (loadId !== throttlerId.current) { return; }
        setIsLoading(false);
        setHighlightIndex(allowFreeInput ? null : 0);
        setItems(res?.options || []);
        setShowItems(true);
      })
      .catch(log.catchAndNotify)
      .finally(() => {
        if (loadId !== throttlerId.current) { return; }
        setIsLoading(false);
      })
      .done();
  }, [ allowFreeInput, throttler, loadOptions, inputValue, readOnly, disabled ]);

  // Watch for changes in value and sync state
  const lastValue = useRef(multiple ? value.length : value);
  if (multiple ? lastValue.current !== value.length : lastValue.current !== value) {
    throttlerId.current++;
    if (lastAction.current === 'resetFreeInput') {
      lastAction.current = null;
    } else {
      setInputValue(multiple ? '' : (value ? value[nameKey] : ''));
    }
    setHighlightIndex(allowFreeInput ? null : 0);
    setIsLoading(false);
    lastValue.current = multiple ? value.length : value;
    if (showItems) { reloadOptions(); }
  }

  const tryPushValue = useCallback(item => {
    if (!onChange) { return; }

    if (multiple) {
      const items = value.slice(0);
      let hasChange = false;
      const arr = Array.isArray(item) ? item : [ item ];
      for (const i of arr) {
        if (!value.find(v => v[valueKey] === i[valueKey])) {
          hasChange = true;
          items.push(i);
        }
      }
      if (hasChange) { onChange(items); }
    } else {
      if (!value || value[valueKey] !== item[valueKey]) {
        onChange(item);
      }
    }
  }, [ multiple, onChange, value, valueKey ]);

  const onInputChangeInternal = useCallback((v, doNotReload) => {
    // If we have no value, clear the actual value
    // If we are allowing free input, clear it out straight away
    if (!multiple && value) {
      onSelect && onSelect(null);
      !onSelect && onChange && onChange(null);
      if (allowFreeInput) {
        lastAction.current = 'resetFreeInput';
      } else {
        v = '';
      }
    }

    setInputValue(v);
    onInputChange && onInputChange(v);
    if (!doNotReload) {
      setTimeout(() => reloadOptions(v), 0);
    }
  }, [ multiple, onChange, onInputChange, onSelect, value, allowFreeInput, reloadOptions ]);

  const onInputFocus = useCallback(e => {
    if (multiple || !value) {
      onInputChangeInternal(e.target.value, lastAction.current === 'onChange');
    } else if (!multiple && (!value || alwaysShowOptionsOnFocus) && !showItems && lastAction.current !== 'onChange') {
      reloadOptions('');
    }
    lastAction.current = null;
    onFocus && onFocus(e);
  }, [ multiple, onFocus, value, alwaysShowOptionsOnFocus, onInputChangeInternal, reloadOptions, showItems ]);

  const onInputBlurInnerFunc = useRef(null);
  onInputBlurInnerFunc.current = (e) => {
    inputBlurTimeout.current = null;

    // If we regained focus then no problems
    if (document.activeElement === inputRef.current || (containerRef.current?.contains(document.activeElement))) { return; }

    throttlerId.current++;

    const currentName = (value ? value[nameKey] : '');
    const newValue = multiple ? '' : (allowFreeInput ? inputValue : currentName);

    setIsLoading(false);
    setInputValue(newValue);
    setItems([]);
    setShowItems(false);
    onBlur && onBlur(e);

    if (allowFreeInput) {
      if (newValue) {
        if (newValue !== currentName) {
          tryPushValue(onFreeInput
            ? onFreeInput(newValue)
            : {
              [nameKey]: newValue,
              [valueKey]: newValue
            });
        }
      } else if (!multiple) {
        onSelect && onSelect(null);
        !onSelect && onChange && onChange(null);
      }
    }
  };

  const onInputBlur = useCallback(e => {
    e.persist();
    if (inputBlurTimeout.current) { clearTimeout(inputBlurTimeout.current); }
    inputBlurTimeout.current = setTimeout(() => onInputBlurInnerFunc.current(e), 0);
  }, []);

  const onInputPaste = useCallback(e => {
    if (!multiple || !allowFreeInput) { onPaste && onPaste(e); return; }

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');
    if (pastedData?.includes('\n')) {
      const values = pastedData.split('\n').filter(s => s.trim()).map(s => onFreeInput
        ? onFreeInput(s)
        : {
          [nameKey]: s,
          [valueKey]: s
        });
      tryPushValue(values);
      e.stopPropagation();
      e.preventDefault();
    } else {
      onPaste && onPaste(e);
    }
  }, [ allowFreeInput, multiple, nameKey, onFreeInput, onPaste, tryPushValue, valueKey ]);

  const onDelete = useCallback(v => {
    if (!multiple || !v) { return; } // We shouldn't even be here...

    const removed = value.slice(0).filter(vv => vv !== v);
    onChange && onChange(removed);
  }, [ multiple, value, onChange ]);

  const onInputClick = useCallback(e => {
    if (!showItems && !disabled && (!value || alwaysShowOptionsOnFocus)) {
      reloadOptions(multiple || !value ? undefined : '');
    }
    onClick && onClick(e);
  }, [ reloadOptions, multiple, value, onClick, showItems, disabled, alwaysShowOptionsOnFocus ]);

  const onInputKeyDown = useCallback(e => {
    let shouldCallParent = true;
    switch (e.key) {
      case 'Enter': {
        if (showItems || allowFreeInput) {
          shouldCallParent = false;
          e.preventDefault();
          throttlerId.current++;
          let newInputValue;
          if (highlightIndex != null && items && items.length && highlightIndex >= 0 && highlightIndex < items.length) {
            const i = items[highlightIndex];
            if (i.isDisabled || i.type === 'subheader') { return; }
            if (onSelect) {
              onSelect(i);
            } else {
              tryPushValue(i);
            }
            newInputValue = !multiple && i ? i[nameKey] : '';
          } else if (allowFreeInput) {
            newInputValue = multiple ? '' : inputValue;
            if (inputValue) {
              tryPushValue(onFreeInput
                ? onFreeInput(inputValue)
                : {
                  [nameKey]: inputValue,
                  [valueKey]: inputValue
                });
            }
          } else {
            newInputValue = !multiple && value ? value[nameKey] : '';
          }
          if (clearOnSelect) { newInputValue = ''; }
          setIsLoading(false);
          setInputValue(newInputValue);
          setItems([]);
          setShowItems(false);
        }
        break;
      }
      case 'Escape':
        if (showItems) {
          shouldCallParent = false;
          e.preventDefault();
          throttlerId.current++;
          setIsLoading(false);
          setInputValue(clearOnSelect ? '' : (!multiple && value ? value[nameKey] : ''));
          setItems([]);
          setShowItems(false);
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        shouldCallParent = false;
        if (showItems && items.length) {
          if (highlightIndex == null) {
            setHighlightIndex(0);
          } else if (highlightIndex + 1 < items.length) {
            setHighlightIndex(highlightIndex + 1);
          }
        } else {
          reloadOptions(multiple || !value ? undefined : '');
        }
        break;
      case 'ArrowUp':
        if (showItems && highlightIndex != null && items.length) {
          shouldCallParent = false;
          if (highlightIndex > 0) {
            setHighlightIndex(highlightIndex - 1);
          } else if (allowFreeInput) {
            setHighlightIndex(null);
          }
          e.preventDefault();
        }
        break;
      case 'Backspace':
        if (showItems && multiple && !inputValue && value.length) {
          shouldCallParent = false;
          onDelete(value[value.length - 1]);
        }
        break;
    }

    if (shouldCallParent) {
      onKeyDown && onKeyDown(e);
    }
  }, [ allowFreeInput, clearOnSelect, highlightIndex, inputValue, items, multiple, nameKey, onDelete, onFreeInput, onKeyDown, tryPushValue, onSelect, value, valueKey, reloadOptions, showItems ]);

  const onItemClicked = useCallback(i => {
    throttlerId.current++;
    setIsLoading(false);
    setInputValue(multiple || clearOnSelect ? '' : i[nameKey]);
    setItems([]);
    setShowItems(false);
    lastAction.current = 'onChange';

    // If we have onSelect, then don't execute onChange
    if (onSelect) {
      onSelect(i);
    } else {
      tryPushValue(i);
    }

    if (multiple || !clearOnSelect) { setTimeout(() => inputRef.current?.focus(), 0); }
  }, [ clearOnSelect, multiple, nameKey, onSelect, tryPushValue ]);

  const inputId = `SelectAsync_input_${instanceId}`;
  const containerId = `SelectAsync_container_${instanceId}`;

  return <Tether
    autoPlacement
    open={showItems && !disabled}
    renderTarget={ref => {
      const targetProps = {
        value, inputProps, valueKey, nameKey, isLoadingExternal, multiple, hideMultipleItems, readOnly, disabled, hasLabel, // eslint-disable-line @stylistic/object-property-newline
        inputId, containerId, showItems, highlightIndex, isLoading, inputValue, // eslint-disable-line @stylistic/object-property-newline
        onDelete, onInputChange: onInputChangeInternal, onFocus: onInputFocus, onBlur: onInputBlur, // eslint-disable-line @stylistic/object-property-newline
        onPaste: onInputPaste, onKeyDown: onInputKeyDown, onClick: onInputClick, // eslint-disable-line @stylistic/object-property-newline
        ...other
      };

      return <SelectInput ref={combineRefs(ref, inputRef, outerRef)} {...targetProps} />;
    }}
    renderElement={ref => {
      const elementProps = {
        containerHeight, rowHeight, variableHeight, allowFreeInput, menuWidth, emptyWithNoItems, isLoadingExternal, loadOptions, value, valueKey, nameKey, renderItem, multiple, // eslint-disable-line @stylistic/object-property-newline
        containerId, highlightIndex, isLoading, inputValue, items, // eslint-disable-line @stylistic/object-property-newline
        setShowItems, setInputValue, onItemClicked // eslint-disable-line @stylistic/object-property-newline
      };

      const inputWidth = inputRef.current.parentNode.getBoundingClientRect().width;

      return <SelectContainer ref={combineRefs(ref, containerRef)} {...elementProps} inputWidth={inputWidth} />;
    }}
  />;
}));

const SelectInput = observer(forwardRef(function SelectInput(props, ref) {
  const {
    // top level props
    value, inputProps, valueKey, nameKey, isLoadingExternal, multiple, hideMultipleItems, readOnly, disabled, hasLabel,
    // internal props
    inputId, containerId, showItems, highlightIndex, isLoading, inputValue, onDelete, onInputChange,
    // input props
    endAdornment, ...other
  } = props;
  const classes = useStyles();

  const handleChange = useCallback(e => onInputChange(e.target.value), [ onInputChange ]);

  const accessProps = readOnly
    ? inputProps
    : Object.assign({
      type: 'search',
      role: 'combobox',
      autoComplete: 'off',
      'aria-autocomplete': 'list',
      'aria-expanded': showItems,
      'aria-owns': showItems ? containerId : inputId,
      'aria-activedescendant': showItems && highlightIndex != null ? `${containerId}_item_${highlightIndex}` : null
    }, inputProps);

  const input = <Input
    id={inputId}
    value={inputValue}
    readOnly={readOnly}
    disabled={disabled}
    onChange={handleChange}
    classes={{ input: cc(classes.input, multiple && classes.multiInput) }}
    inputRef={ref}
    inputProps={accessProps}
    endAdornment={(isLoading || isLoadingExternal) ? <InputAdornment position="end"><CircularProgress size={16} className={classes.progress} /></InputAdornment> : endAdornment}
    {...other}
  />;

  if (multiple && !hideMultipleItems) {
    const valKey = valueKey || 'value';
    const nKey = nameKey || 'name';
    const canEdit = !(readOnly || disabled);
    const handleDelete = v => (canEdit ? () => onDelete(v) : undefined);
    return <Grid key="multi" container spacing={1} alignItems="center" style={hasLabel ? { marginTop: 16 } : undefined}>
      { value.map(v => (
        <Grid item key={v[valKey]} className={classes.maxWidth}>
          <Chip label={v[nKey]} onDelete={handleDelete(v)} className={classes.maxWidth} />
        </Grid>
      ))}
      { canEdit &&
        <Grid item xs>
          {input}
        </Grid>
      }
    </Grid>;
  } else {
    return input;
  }
}));

const SelectContainer = observer(forwardRef(function SelectContainer(props, ref) {
  const classes = useStyles();
  let {
    // top level props
    containerHeight, rowHeight, variableHeight, allowFreeInput, menuWidth, emptyWithNoItems, isLoadingExternal, loadOptions, value, valueKey, nameKey, renderItem, multiple,
    // internal props
    containerId, isLoading, inputValue, highlightIndex, items, inputWidth,
    setShowItems, setInputValue, onItemClicked
  } = props;

  const containerRef = useRef(null);
  const handleNoItemsClick = useCallback(() => {
    setShowItems(false);
    if (!allowFreeInput) {
      setInputValue('');
    }
  }, [ allowFreeInput, setShowItems, setInputValue ]);

  const renderItemFunc = useCallback((i, isHighlighted, heightChanged) => {
    if (renderItem) { return renderItem(i, isHighlighted, inputValue, heightChanged); }

    // Underline searched text by default
    let text = i[nameKey];
    if (text && inputValue && (multiple || !value)) {
      const regex = new RegExp(inputValue.split(/\s+/g).map(v => escapeRegExp(v)).join('|'), 'gi');
      const children = [];
      let lastIndex = 0;
      for (const m of text.matchAll(regex)) {
        if (m.index !== lastIndex) {
          children.push(<span key={children.length}>{text.substring(lastIndex, m.index)}</span>);
        }
        children.push(<u key={children.length}>{m[0]}</u>);
        lastIndex = m.index + m[0].length;
      }
      if (lastIndex !== text.length) {
        children.push(<span key={children.length}>{text.substring(lastIndex)}</span>);
      }
      text = <Fragment>{children}</Fragment>;
    } else if (!text) {
      text = '(Blank)';
    }
    return <ListItemText primary={text} />;
  }, [ renderItem, inputValue, nameKey, multiple, value ]);

  if (!loadOptions || (!items.length && !emptyWithNoItems && !inputValue)) { return null; }

  const width = menuWidth || inputWidth;
  rowHeight = rowHeight || 48;
  containerHeight = containerHeight || 360;

  const commonProps = {
    value,
    valueKey,
    nameKey,
    renderItem: renderItemFunc,
    multiple,
    highlightIndex,
    containerId,
    onClick: onItemClicked
  };

  return <Paper square className={classes.padded} style={{ width: width + 'px' }} id={containerId} ref={ref}>
    <MenuList disablePadding>
      { (!isLoading && !isLoadingExternal) && !items.length && (emptyWithNoItems || inputValue) &&
        <MenuItem onClick={handleNoItemsClick}>
          <ListItemText primary="No items found" />
        </MenuItem>
      }
      { !!items.length &&
        <div style={{ maxHeight: containerHeight, overflow: 'auto' }} ref={containerRef}>
          { /*
            If we have less than 20 items, just render them directly otherwise use virtual list.
            This is to prevent strange glitch when the number of items is less than the height of the container
            Which leads to continuous height calculations
          */ }
          { items.length <= 20 && items.map((i, index) => (
            i.type === 'subheader'
              ? <ListSubheader key={index}>{i[nameKey]}</ListSubheader>
              :
              <ListItem
                key={index}
                item={i}
                index={index}
                variableHeight={variableHeight}
                containerRef={containerRef}
                { ...commonProps }
              />
          ))}
          { items.length > 20 &&
            <VirtualList
              container={containerRef}
              InnerComponent={ListItemRenderer}
              items={items}
              itemHeight={variableHeight ? undefined : rowHeight}
              estimatedItemHeight={variableHeight ? rowHeight : undefined}
              variableHeight={variableHeight}
              { ...commonProps }
            />
          }
        </div>
      }
    </MenuList>
  </Paper>;
}));

const ListItemRenderer = observer(forwardRef(function ListRendererComponent({ virtual, valueKey, nameKey, ...other }, ref) {
  return <div ref={ref} style={virtual.style}>
    { virtual.items.map(props =>
      props.item.type === 'subheader'
        ? <ListSubheader key={props.index}>{props[nameKey]}</ListSubheader>
        :
        <ListItem
          key={props.index}
          valueKey={valueKey}
          nameKey={nameKey}
          {...other}
          {...props}
        />
    )}
  </div>;
}));

const ListItem = observer(function ListItem({ item: i, index, style, variableHeight, setHeight, value, valueKey, nameKey, renderItem, multiple, highlightIndex, containerId, containerRef, onClick }) {
  const theme = useTheme();
  const isHighlighted = highlightIndex === index;
  const isSelected = multiple ? !!value.find(v => v[valueKey] === i[valueKey]) : (value && value[valueKey] === i[valueKey]);

  const menuRef = useRef(null);

  const handleClick = useCallback(() => onClick && onClick(i), [ i, onClick ]);
  const calcHeight = useCallback(() => {
    if (!variableHeight || !setHeight) { return; }
    if (menuRef.current) {
      setHeight(index, menuRef.current.offsetHeight);
    }
  }, [ variableHeight, setHeight, index ]);

  useEffect(calcHeight);

  useEffect(() => {
    if (!isHighlighted || !containerRef?.current || !menuRef.current) { return; }
    containerRef.current.scrollTop = menuRef.current.offsetTop;
  }, [ isHighlighted, containerRef ]);

  return <div style={style}>
    <MenuItem
      ref={menuRef}
      id={`${containerId}_item_${index}`}
      role="option"
      selected={isHighlighted}
      disabled={i.isDisabled}
      onClick={handleClick}
      component="div"
      style={{
        whiteSpace: variableHeight ? 'normal' : undefined,
        fontWeight: isSelected
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular
      }}
    >
      { renderItem(i, isHighlighted, calcHeight) }
    </MenuItem>
  </div>;
});